// @import "node_modules/bootstrap/dist/css/bootstrap.min.css";

/* You can add global styles to this file, and also import other style files */
html {
    height: 100%;
}

body {
    margin: 0;
    font-size: 14px;    
    font-family: 'Prompt', sans-serif;
    // background-color: rgb(251, 247, 251);

    // min-height: 100vh;

    background-color: #f5f5f5;
    background: url('/assets/p1.webp');    

    background-position:top;
	background-repeat: no-repeat; 	
	background-size : cover;

    // height: 100%;
}

footer {
    background-color: white;
}

.main-header {
    min-height: 70px;
    background-color: white;
    opacity: 0.9;
}

.form-label {
    color: #777;
    margin-bottom:0;
    font-weight: 300;
}

.form-floating>label {
    padding: 0.6rem 0.4rem;
}

.form-floating>.form-control, .form-floating>.form-select {
    height: calc(3rem + 2px);
    line-height: 1.25;
    padding: 0.5rem 0.5rem;
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    padding-bottom: 0.625rem;
}

.modal-dialog {
    min-height: calc(100% - 3.5rem);
    display: flex;
    align-items: center;
}

.main-body {
    margin-top: 80px;
}


// .backdrop {

//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: rgba(87, 84, 84, 0.25);

//     z-index: 999;

//     padding-left: 20px;
//     padding-top: 70px;    
// }

// .backdrop div {
//     padding: 10px; padding-left: 10px; padding-right: 40px; 
//     // width: 400px; //
//     align-items: center;
//     width: 500px; 
//     margin-left: auto; margin-right: 20px; 
//     min-height: 60px;
//     min-width: 500px;
//     opacity: 100;
//     // background-color: #fff;
//     background-color: rgba(245, 246, 248, 0.8);
//     color : #000000;
// }


.btn {
    font-family: 'Prompt', sans-serif;
}

.form-control[readonly], .form-control:disabled {
    background-color: #fff;
    padding: 0px;
    height: inherit;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
    border-style: none;
    border-width: 0px;
    border-bottom: 1px solid #eee;
    color: black;
}


.form-group label {
    font-family: 'Prompt', sans-serif;
    display: inline-block;
    font-size: .9rem;
    margin-bottom: 0px;
    // color : #777;
}


pre {
    font-size: inherit;
    font-family: inherit;
    
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    // margin-bottom: 1px;
    // border-bottom: #eee solid 1px;
}

// ตั้งค่า pagination
.page-item {
    .material-icons {
        line-height: inherit;
        font-size : 1rem;
    }

    .page-link {
        padding-top: 0;
        padding-bottom: 0;        
    }
}


.google-font {
    //font-family: 'Bai Jamjuree', sans-serif;
    font-family: 'Prompt', sans-serif;
}


h1, h2, h3, h4, h5, h6 {
    //font-family: 'Bai Jamjuree', sans-serif;
    font-family: 'Prompt', sans-serif;
    .small {
        font-size: 0.6em;
        font-weight: 200;
        color: gray;
    }
}



